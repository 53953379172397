import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';

$(document).ready(function() {


    $('.follow-us .back_to_top').click(function(e) {
        e.preventDefault();
        $("html, body").animate({scrollTop: 0}, 1000);
    });



    $('.s-box').click(function (e) {
        e.preventDefault();
        var target = $(this).attr('target');
        console.log(target);
        $('.'+target).addClass('on');
        $('.popup-mask').addClass('on');
    })

    $('.popup-close-btn, .popup-mask').click(function (e) {
        e.preventDefault();
        $('.popup').removeClass('on');
        $('.popup-mask').removeClass('on');
    })

    /*
    $('.s-box, .popup-close-btn').click(function (e) {
        e.preventDefault();
        var target = $('.popup');
        target.hasClass('on') ? target.removeClass('on') : target.addClass('on');
    });
    */

});
new Swiper('.home-banner .swiper-container', {
    freeMode: false,
    autoplay: {
        delay: 5000,
    },
    pagination: {
        el: '.swiper-pagination',
    },
    speed: 1000,
    loop: true,
});
new Swiper('.aji-brand  .swiper-container', {
    slidesPerView: 5,
    spaceBetween: 30,
    freeMode: false,
    autoplay: {
        delay: 1000,
    },
    speed: 1000,
    loop: true,
    breakpoints: {
        1024: {
            slidesPerView: 3,
            spaceBetween: 44
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 44
        },
        576: {
            slidesPerView: 2,
            spaceBetween: 20
        }
    },
    navigation: {
        nextEl: '.logos-right-btn',
        prevEl: '.logos-left-btn',
    },
    /*pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },*/
});
new Swiper('.aji-heart-made .swiper-container', {
    slidesPerView: 5,
    spaceBetween: 40,
    freeMode: false,
    autoplay: {
        delay: 1000,
    },
    speed: 1500,
    loop: true,
    breakpoints: {
        1280: {
            slidesPerView: 4,
            spaceBetween: 44
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 44
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 44
        },
        576: {
            slidesPerView: 2,
            spaceBetween: 44
        }
    },
    navigation: {
        nextEl: '.logos-right-btn',
        prevEl: '.logos-left-btn',
    },
    /*pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },*/
});
